import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { Colors, ISongs } from '../../config';
import { PopupOpeningTypes } from '../../consts';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { calcBottomContainerHeight, calcPercentage, isFreeSpinsGameMode } from '../../utils';
import Tween from '../animations/tween';
import AutoResizeText from '../components/autoResizeText';
import { layerBuyFeatureBtn } from '../components/layers/layers';
import { EventTypes, eventManager } from '../config';

import {
  FEATURE_BTN_DEFAULT_SCALE,
  FEATURE_BTN_LANDSCAPE_X,
  FEATURE_BTN_LANDSCAPE_Y,
  FEATURE_BTN_MOUSE_CLICK_SCALE,
  FEATURE_BTN_MOUSE_OVER_SCALE,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
} from './config';
import { buyFeatureBtnTextStyle } from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  private isDisabled: boolean;

  private intent: string;

  private scaleContainer: PIXI.Container;

  private isFreeRoundBonus: boolean;

  private isInTransition = false;

  public btnContainer: PIXI.Container;

  constructor() {
    super();
    this.intent = 'buy_button';
    this.isDisabled = false;
    this.isFreeRoundBonus = false;
    this.visible = !setBrokenGame();

    this.btn = this.initBuyFeatureBtn();
    this.text = this.initBuyFeatureText();

    this.btnContainer = this.initBtnContainer();
    this.btnContainer.addChild(this.btn, this.text);
    this.scaleContainer = new PIXI.Container();
    this.scaleContainer.addChild(this.btnContainer);

    this.addChild(this.scaleContainer);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = !isFreeSpinsGameMode(mode);
    });
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isInTransition: boolean) => {
      this.isInTransition = isInTransition;
      this.handleDisable(isInTransition);
    });

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    this.parentLayer = layerBuyFeatureBtn;
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(`${this.intent}`));
    btn.anchor.set(0.5);
    btn.tint = Colors.GAME_COLOR;
    return btn;
  }

  private initBtnContainer() {
    const container = new PIXI.Container();
    container.interactive = true;
    container.buttonMode = true;
    container.hitArea = new PIXI.Rectangle(
      -this.btn.texture.width / 2,
      -this.btn.texture.height / 2,
      this.btn.texture.width,
      this.btn.texture.height,
    );
    container.scale.set(FEATURE_BTN_DEFAULT_SCALE);
    container.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) this.onClick();
    });
    container.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) this.onClick();
    });

    container.addListener('mouseover', () => {
      if (!this.isDisabled) {
        //this.btn.texture = PIXI.Texture.from(`${this.intent}_over`);
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.scaleContainer.scale.set(FEATURE_BTN_MOUSE_OVER_SCALE);
        this.scaleContainer.position.set(0, 0);
      }
    });
    container.addListener('mouseout', () => {
      if (!this.isDisabled) {
        //this.btn.texture = PIXI.Texture.from(`${this.intent}`);
        this.scaleContainer.scale.set(FEATURE_BTN_DEFAULT_SCALE);
        this.scaleContainer.position.set(0, 0);
      }
    });
    container.addListener('mousedown', () => {
      if (!this.isDisabled) {
        //this.btn.texture = PIXI.Texture.from(`${this.intent}_click`);
        this.scaleContainer.scale.set(FEATURE_BTN_MOUSE_CLICK_SCALE);
        this.scaleContainer.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
      }
    });
    container.addListener('mouseup', () => {
      if (!this.isDisabled) {
        //this.btn.texture = PIXI.Texture.from(`${this.intent}`);
        this.scaleContainer.scale.set(FEATURE_BTN_DEFAULT_SCALE);
        this.scaleContainer.position.set(0, 0);
      }
    });
    return container;
  }
  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('buyFeature.button'), buyFeatureBtnTextStyle);
    text.anchor.set(0.5);
    text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    text.interactive = false;
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      this.scaleContainer.scale.set(FEATURE_BTN_DEFAULT_SCALE);
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsOpenInfoPopup(false);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioApi.play({ type: ISongs.XT004S_push_buy_feature });
      Tween.setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private setObjAlpha(alpha: number) {
    //this.btn.alpha = alpha;
    //this.text.alpha = alpha;
    this.btnContainer.alpha = alpha;
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isInTransition && !disable) return;

    this.isDisabled = disable;
    this.btnContainer.buttonMode = !disable;

    if (disable) {
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      this.setObjAlpha(0.5);
      this.scaleContainer.scale.set(FEATURE_BTN_DEFAULT_SCALE);
      //this.btn.texture = PIXI.Texture.from(`${this.intent}_disable`);
      //this.text.tint = 0x888888;
    } else {
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      this.setObjAlpha(1);
      //this.btn.texture = PIXI.Texture.from(`${this.intent}`);
      //this.text.tint = 0xffffff;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private gameContainerResize(width: number, height: number, scale: number): void {
    const isLandscape = width >= height;

    if (isLandscape) {
      this.pivot.set(0, 0);
      this.x = this.btn.width / 2 + FEATURE_BTN_LANDSCAPE_X;
      this.y = this.btn.height / 2 + FEATURE_BTN_LANDSCAPE_Y;
      this.scale.set(FEATURE_BTN_DEFAULT_SCALE);
    } else {
      this.pivot.set(this.btn.width / 2, this.btn.height / 2);

      height = height - calcBottomContainerHeight(width, height);
      const betBtnSize = calcPercentage(height, 7.5);
      const betBtnGap = calcPercentage(width, 1.25);
      const betBtnTop = height - betBtnSize - betBtnGap;
      const buyBtnWGap = calcPercentage(width, 1.25);
      const buyBtnHGap = calcPercentage(height, 1);
      const buyBtnscale = Math.min(
        calcPercentage(height, 7.5) / scale / this.btn.height,
        calcPercentage(width, 20) / scale / this.btn.width,
      );

      this.scale.set(buyBtnscale);
      this.parent.toLocal({ x: width - buyBtnWGap, y: betBtnTop - buyBtnHGap }, undefined, this.position, false);
    }
  }
}

export default BuyFeatureBtn;
