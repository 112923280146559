import { getFragmentData } from '../../gql';
import {
  setBrokenGame,
  setCurrentBonus,
  setLastBaseReelPositions,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { historyNodeFragment } from '../../gql/fragment';
import { replaySlotHistoryGql, slotHistoryGql } from '../../gql/query';
import { FREE_SPIN_START_REEL_POSITIONS } from '../../slotMachine/config';
import { getBonusKind, getLastRegularBetReelPositions, getNonNullableValue, queryParams } from '../../utils';

import { BonusKind } from './../../slotMachine/config/bonusInfo';

export const useLastBet = () => {
  const query = queryParams.has('replayBetId') ? replaySlotHistoryGql : slotHistoryGql;
  const getLastBet = async () => {
    const betsData = await client.query({
      query,
      variables: {
        input: { last: 1, filter: { slotId: setSlotConfig().id } },
      },
      fetchPolicy: 'network-only',
    });
    if (betsData.data.bets?.edges[0]) {
      const node = getFragmentData(historyNodeFragment, betsData.data.bets.edges[0].node);
      setUserLastBetResult(node);
    }
  };

  const getLastBetFromBonusBetId = async () => {
    const lastBetData = setUserLastBetResult();
    if (!lastBetData) return;

    const isBrokenGame = setBrokenGame();
    let reelPositions = lastBetData.result.reelPositions;
    let reelSetId = lastBetData.reelSetId;
    let lastRegularReelPositions: number[] = [];

    if (lastBetData.userBonus && lastBetData.userBonus?.betId) {
      lastRegularReelPositions = await getLastRegularBetReelPositions(lastBetData.userBonus?.betId!);
      setLastBaseReelPositions(getNonNullableValue(lastRegularReelPositions));
    } else {
      setLastBaseReelPositions(getNonNullableValue(lastBetData.result.reelPositions));
    }

    //respin end
    if (lastBetData.data.features.gameRoundStore.freezeRespinData.respinReelPositions) {
      reelPositions = lastBetData.data.features.gameRoundStore.freezeRespinData.respinReelPositions;
    }
    //base to FreeSpin
    if (isBrokenGame) {
      if (
        !lastBetData.userBonus ||
        (lastBetData.userBonus && getBonusKind(lastBetData.userBonus.bonusId) === BonusKind.BUY_FEATURE)
      ) {
        if (getBonusKind(setCurrentBonus().bonusId) === BonusKind.FREE_SPINS) {
          reelPositions = FREE_SPIN_START_REEL_POSITIONS;
          reelSetId = setCurrentBonus().bonus?.reelSetId!;
        }
      }
    }
    setUserLastBetResult({
      ...lastBetData!,
      reelSetId: reelSetId,
      result: {
        ...lastBetData!.result,
        reelPositions: reelPositions,
      },
    });
  };

  return {
    getLastBet,
    getLastBetFromBonusBetId,
  };
};
