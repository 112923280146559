import { Container, Graphics, Sprite, Text, Texture } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setCurrency, setFreeRoundsTotalWin, setIsFreeRoundBonus, setIsPopupFreeRoundsOpened } from '../../gql/cache';
import i18n from '../../i18next';
import { normalizeCoins, showCurrency, updateCoinValueAfterBonuses } from '../../utils';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { layerFreeRoundsPopUp } from '../components/layers/layers';
import { EventTypes, eventManager } from '../config';

import { LAYOUT_OPTIONS, buttonTextStyle, freeRoundsTextStyles } from './config';

export class FreeRoundsEndPopup extends ViewContainer {
  protected popup: Container;

  protected background: Sprite;

  protected roundsLabel: Text;

  protected roundsAmount: Text;

  protected freeRounds: Text;

  protected backgroundFadeInAnimation: Tween;

  protected backgroundFadeOutAnimation: Tween;

  protected isExpired = false;

  private bindedCallback = () => {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = false;

    eventManager.emit(EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS, {
      inDuration: 2000,
      outDuration: 1000,
      callback: () => {
        setIsPopupFreeRoundsOpened(false);
        setIsFreeRoundBonus(false);
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeRoundsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );
        updateCoinValueAfterBonuses();
      },
    });
  };

  constructor() {
    super();
    this.visible = false;
    this.background = this.initBackground();
    this.roundsLabel = this.initRoundsLabel();
    this.roundsAmount = this.initRoundsAmount();
    this.freeRounds = this.initFreeRoundsText();
    this.popup = this.initPopup();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();

    this.init();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.OPEN_POPUP_FREE_ROUNDS_END, (isExpired?: boolean) => {
      this.show(isExpired);
    });
  }

  protected init() {
    this.addChild(this.background);
    this.addChild(this.popup);

    this.parentLayer = layerFreeRoundsPopUp;
  }

  private initBackground() {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.5;
    sprite.interactive = true;
    return sprite;
  }

  protected initPopup() {
    const container = new Container();
    const innerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.bgColor)
      .drawRoundedRect(0, 0, LAYOUT_OPTIONS.width, LAYOUT_OPTIONS.height, LAYOUT_OPTIONS.borderRadius)
      .endFill();
    innerBg.position.set(LAYOUT_OPTIONS.border);
    const outerBg = new Graphics()
      .beginFill(LAYOUT_OPTIONS.borderColor)
      .drawRoundedRect(
        0,
        0,
        LAYOUT_OPTIONS.width + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.height + 2 * LAYOUT_OPTIONS.border,
        LAYOUT_OPTIONS.borderRadius,
      )
      .endFill();
    const closeBtn = this.createButton();

    container.addChild(outerBg, innerBg, closeBtn, this.freeRounds, this.roundsAmount, this.roundsLabel);
    closeBtn.x = LAYOUT_OPTIONS.width / 2 - closeBtn.width / 2;
    closeBtn.y = LAYOUT_OPTIONS.height - (closeBtn.height * 3) / 2;

    return container;
  }

  private initBackGroundFadeInAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 200,
    });
  }

  private initBackGroundFadeOutAnimation() {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 1000,
    });
  }

  private initRoundsLabel() {
    const text = new Text(i18n.t('freeRoundsCompleted'), freeRoundsTextStyles);
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 57);
    return text;
  }

  private initRoundsAmount() {
    const text = new Text('', { ...freeRoundsTextStyles, fontSize: 50 });
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 115);
    return text;
  }

  private initFreeRoundsText() {
    const text = new Text(i18n.t('returnToTheGame'), freeRoundsTextStyles);
    text.anchor.set(0.5, 0.5);
    text.position.set(215, 175);
    return text;
  }

  protected resize(width: number, height: number) {
    this.background.width = width;
    this.background.height = height;
    this.background.position.set(width / 2, height / 2);

    if (width - 50 < LAYOUT_OPTIONS.width) {
      this.popup.scale.set(width / (LAYOUT_OPTIONS.width + 50));
    } else if (height - 100 < LAYOUT_OPTIONS.height) {
      this.popup.scale.set(height / (LAYOUT_OPTIONS.height + 100));
    } else {
      this.popup.scale.set(1);
    }
    this.popup.y = height / 2 - this.popup.height / 2;
    this.popup.x = width / 2 - this.popup.width / 2;
  }

  public show(isExpired = false) {
    setIsPopupFreeRoundsOpened(true);
    this.isExpired = isExpired;
    this.roundsAmount.visible = true;
    this.roundsLabel.y = 57;
    this.freeRounds.y = 175;
    if (setFreeRoundsTotalWin()) {
      this.roundsAmount.text = `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeRoundsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`;
    } else {
      this.roundsLabel.y = 89;
      this.freeRounds.y = 155;
      this.roundsAmount.visible = false;
    }
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = true;
  }

  private createButton() {
    const width = LAYOUT_OPTIONS.width / 3;
    const height = LAYOUT_OPTIONS.width / 8;
    const borderWidth = 3;
    const { borderColor } = LAYOUT_OPTIONS;
    const radius = LAYOUT_OPTIONS.borderRadius;

    const button = new Graphics();

    button
      .lineStyle(borderWidth, borderColor)
      .moveTo(width - radius, 0)
      .arcTo(width, 0, width, radius, radius)
      .lineTo(width, height - radius)
      .arcTo(width, height, width - radius, height, radius)
      .lineTo(radius, height)
      .arcTo(0, height, 0, height - radius, radius);

    button.beginFill(LAYOUT_OPTIONS.buttonColor).drawRoundedRect(0, 0, width, height, radius).endFill();

    const text = new Text(i18n.t('close'), buttonTextStyle);
    text.anchor.set(0.5);
    text.position.set(width / 2, height / 2);

    button.interactive = true;
    button.cursor = 'pointer';
    button.on('pointertap', () => this.bindedCallback());
    button.addChild(text);

    return button;
  }
}
