import { getFragmentData } from '../../gql';
import {
  setBonuses,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { userBonusFragment } from '../../gql/fragment';
import { getBonuses, getReplayUserBonuses, getUserBonuses } from '../../gql/query';
import { BonusKind, freeRoundBonusId } from '../../slotMachine/config/bonusInfo';
import { calcActiveUserBonusTotalWinAmount, getBonusKind, getNonNullableValue, queryParams } from '../../utils';

export const useBonuses = () => {
  const getPurchasableBonuses = async () => {
    const bonusData = await client.query({
      query: getBonuses,
      variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    setBonuses(bonusData.data.bonuses);
  };

  const checkBonusGame = async () => {
    const query = queryParams.has('replayBetId') ? getReplayUserBonuses : getUserBonuses;
    const activeUserBonusData = await client.query({
      query,
      variables: {
        input: { status: 'ACTIVE', slotId: setSlotConfig().id },
      },
      fetchPolicy: 'network-only',
    });

    // its locally for testing.
    // ------------------------------
    // const originActiveUserBonusData = await client.query({
    //   query: getUserBonuses,
    //   variables: { input: { status: 'ACTIVE', slotId: setSlotConfig().id } },
    //   fetchPolicy: 'network-only',
    // });
    // const activeUserBonusData: ApolloQueryResult<GetUserBonusesQuery> = JSON.parse(
    //   JSON.stringify(originActiveUserBonusData),
    // );

    // const frbDummyData = makeFragmentData(
    //   {
    //     ...setCurrentBonus(),
    //     id: freeRoundBonusId,
    //     bonusId: freeRoundBonusId,
    //     coinAmount: 1,
    //     coinValue: 100,
    //     rounds: 2,
    //     totalWinAmount: 10000,
    //   },
    //   userBonusFragment,
    // );

    // activeUserBonusData.data.userBonuses!.push(frbDummyData);
    // ------------------------------

    const userBonuses = getFragmentData(userBonusFragment, getNonNullableValue(activeUserBonusData.data.userBonuses));

    const buyFeatureBonus = userBonuses.find((ub) => getBonusKind(ub!.bonusId) === BonusKind.BUY_FEATURE);
    if (buyFeatureBonus) {
      setBrokenBuyFeatureGame(buyFeatureBonus.id);
      setIsBuyFeaturePopupOpened(true);
      setCurrentBonus({
        ...buyFeatureBonus,
        isActive: true,
      });
      setCurrentFreeSpinsTotalWin(0);
      return;
    }

    //TO DO
    const freeSpinsBonus = userBonuses?.find((ub) => getBonusKind(ub!.bonusId) === BonusKind.FREE_SPINS);
    const reSpinsBaseBonus = userBonuses?.find((ub) => getBonusKind(ub!.bonusId) === BonusKind.RESPIN_BASE);
    const reSpinsFreeSpinBonus = userBonuses?.find((ub) => getBonusKind(ub!.bonusId) === BonusKind.RESPIN_FREE_SPIN);

    if (freeSpinsBonus) {
      setBrokenGame(true);
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
      });
      const totalWin = calcActiveUserBonusTotalWinAmount(freeSpinsBonus);
      setCurrentFreeSpinsTotalWin(totalWin);
    } else if (reSpinsBaseBonus) {
      setBrokenGame(true);
      setCurrentBonus({
        ...reSpinsBaseBonus,
        isActive: true,
      });
      const totalWin = calcActiveUserBonusTotalWinAmount(reSpinsBaseBonus);
      setCurrentFreeSpinsTotalWin(totalWin);
    } else if (reSpinsFreeSpinBonus) {
      setBrokenGame(true);
      setCurrentBonus({
        ...reSpinsFreeSpinBonus,
        isActive: true,
      });
      const totalWin = calcActiveUserBonusTotalWinAmount(reSpinsFreeSpinBonus);
      setCurrentFreeSpinsTotalWin(totalWin);
    }

    const freeRoundBonus = userBonuses.find((ub) => ub.bonusId === freeRoundBonusId);
    if (freeRoundBonus && (!freeSpinsBonus || freeSpinsBonus.data?.frbReferenceId)) {
      setFreeRoundBonus({
        ...freeRoundBonus,
        isActive: true,
        roundsPlayed: 0,
      });
      const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
      setFreeRoundsTotalWin(totalWinAmount);
    }
  };
  return {
    getPurchasableBonuses,
    checkBonusGame,
  };
};
