import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, durationOf } from '../../config';
import { GameMode } from '../../consts';
import { MessageFreeSpinEndBannerProps, MessageFreeSpinsBannerProps, MessageLevelUpBannerProps } from '../../global.d';
import { setBetAmount } from '../../gql/cache';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import { layerReel } from '../components/layers/layers';
import {
  BASE_WIN_AMOUNT_LIMIT,
  EventTypes,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  GameViewState,
  SLOT_REELMASK_HEIGHT,
  SLOT_REELMASK_WIDTH,
  SLOT_REELMASK_X,
  SLOT_REELMASK_Y,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { MessageFreeSpinEndBanner } from '../messageBanner/messageFreeSpinEndBanner';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { messageLevelUpBanner } from '../messageBanner/messageLevelUpBanner';
import MessageWindow from '../messageWindow/messageWindow';
import ReelsFrame from '../reels/frame/reelsFrame';
import SnowLevel from '../snow/snowLevel';
import SnowPrize from '../snow/snowPrize';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public reelTintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public messageWindow: PIXI.Container;

  public reelsFrame: ReelsFrame;

  public snowPrize: SnowPrize;

  public snowMeter: PIXI.Container;

  public snowLevel: SnowLevel;

  public announceContainer: PIXI.Container;

  public linesContainer: PIXI.Container;
  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor(props: IGameContainer) {
    super();

    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.messageWindow = new MessageWindow();
    this.reelsFrame = new ReelsFrame();
    this.snowPrize = new SnowPrize();
    this.snowLevel = new SnowLevel();
    this.winLabelContainer = props.winLabelContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.reelTintContainer = props.reelTintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.announceContainer = props.announceContainer;
    this.linesContainer = props.linesContainer;
    this.snowMeter = props.snowMeter;

    this.slotStopDisplayContainer.addChild(this.linesContainer);

    this.slotsContainer.addChild(this.reelTintContainer);
    this.slotsContainer.addChild(this.reelsContainer);

    this.addChild(this.gameTitle);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.snowPrize);
    this.addChild(this.announceContainer);
    this.addChild(this.reelsFrame);
    this.addChild(this.snowMeter);
    this.addChild(this.snowLevel);
    this.addChild(this.messageWindow);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameReplay);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    const mask = new PIXI.Graphics();
    mask.beginFill(0xffffff);
    mask.drawRect(SLOT_REELMASK_X, SLOT_REELMASK_Y, SLOT_REELMASK_WIDTH, SLOT_REELMASK_HEIGHT);
    mask.endFill();
    layerReel.mask = mask;
    this.addChild(mask);
    mask.parentLayer = layerReel;

    eventManager.addListener(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsStartMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createFreeSpinEndMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_LEVELUP_BANNER, this.createLevelUpMessage.bind(this));
    eventManager.addListener(EventTypes.SET_FREESPINS_VIEW, this.setFreeSpinsView.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    //to base
    if (settings.mode === GameMode.REGULAR) {
      this.setBaseGameView();
    }
  }

  private setBaseGameView() {
    eventManager.emit(EventTypes.HIDE_COINS);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);

    this.resetBasePositionByState('Base');
  }

  private setFreeSpinsView() {
    this.resetBasePositionByState('Base');
  }

  private createFreeSpinsStartMessage(props: MessageFreeSpinsBannerProps) {
    this.setFreeSpinsView();
    const messageBanner = new MessageFreeSpinsBanner(props);
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private createLevelUpMessage(props: MessageLevelUpBannerProps) {
    const messageBanner = new messageLevelUpBanner(props);
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }
  private createFreeSpinEndMessage(props: MessageFreeSpinEndBannerProps): void {
    const multiplier = props.totalWinAmount / setBetAmount();
    const endSound =
      multiplier >= BASE_WIN_AMOUNT_LIMIT ? ISongs.XT004S_feature_end_high : ISongs.XT004S_feature_end_low;

    const totalWinDelay = Tween.createDelayAnimation(durationOf(endSound));
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(200);
    });
    totalWinDelay.addOnSkip(() => {
      //QA-89
      //AudioApi.stop({ type: endSound });
      AudioApi.fadeOut(1000, endSound);
    });
    AudioApi.play({ type: endSound, stopPrev: true });
    totalWinDelay.start();

    const messageBanner = new MessageFreeSpinEndBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    });
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private resetBasePositionByState(state: GameViewState) {
    switch (state) {
      case 'Base':
        break;
      default:
        break;
    }
  }
}

export default GameView;
